<template>
    <div class="action-premium-rating">
        <template>
            <div class="message">
                <div class="message-top" v-html="title" />
                <div class="message-button">
                    <button @click="onClickCta" class="btn btn-primary" v-html="content.cta" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { isStackRoute } from '@/router/stack-router'

export default {
    name: 'ActionPremiumRating',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        title() {
            const title = this.content.title.split('.')
            return `${title[0]}. <br> ${title[1]}`
        },
    },
    methods: {
        async onClickCta() {
            if (!this.content.action) return

            if (this.content.action.includes('http://') || this.content.action.includes('https://')) {
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: this.content.action,
                })
                return
            }

            isStackRoute(this.content.action)
                ? this.$stackRouter.push({ name: this.content.action })
                : this.$router.push({ name: this.content.action })
        },
    },
}
</script>

<style lang="scss" scoped>
.action-premium-rating {
    padding: 0 12px;

    .message {
        font-size: 16px;
        line-height: 1.33;
        text-align: left;
        color: black;
        @include f-medium;

        .message-button {
            margin: 12px 0;

            button {
                width: 100%;
                @include f-regular;
                font-size: 14px;
            }
        }
    }
}
</style>
